/* body { */
/*     margin: 0; */
/*     padding: -10px; */
/* } */

body {
    font-family: monospace;
}

header {
    background: black;
    color: yellow;
    padding: 1em 1em;
    margin-bottom: 1em;
}
header a {
    color: yellow;
}

header h1 {
    margin-top: 0;
}

h1,h2,h3 {
    margin-bottom: 0;
}

hr {
    margin-top: 5px;
}

.track-list-item:hover {
    background: yellow;
}

.count-switcher .selected {
    text-decoration: underline;
}

.count-switcher:hover span {
    cursor: pointer;
}
